.about {
    height: 100vh;
    display: flex;
    align-items: center;
}

.a-left {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
}

.a-right {
    flex: 1;
    padding: 0 50px;
}

.a-card.bg {
    position: absolute;
    top: 50px;
    left: 50px;
    background-color: #333;
    box-shadow: 0 0 10px black;
    animation: scaleUp 3s infinite alternate;
}

@keyframes scaleUp {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(0.9);
    }
}

.a-card {
    width: 60%;
    height: 70vh;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 5px rgb(255, 255, 255);
}

.a-card-bg {
    clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);
    width: 100%;
    height: 100%;
    background-color: rgb(218, 218, 218);
    position: absolute;
    left: 0;
    filter: blur(800px);
    -webkit-filter: blur(800px);
    animation: go-right 3s infinite alternate ease-in-out;
}

@keyframes go-right {
    from {
        left: -40px;
    }

    to {
        left: 0;
    }
}

.a-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
}

.a-award-img {
    width: 120px;
    height: 120px;
    border-radius: 20px;
}

.a-title {
    font-size: 2rem;
    font-weight: 400;
    border-bottom: 2px solid gray;
}

.a-sub {
    margin: 20px 0;
}

.a-desc {
    font-weight: 200;
}

.a-award {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.a-award-texts {
    width: 70%;
    font-weight: 300;
}

a.-award-title {
    font-weight: bold;
    color: #555;
    margin-top: 10px;
}


/* RESPONSIVE STYLES */

@media screen and (max-width: 480px) {

    .about {
        flex-direction: column;
        text-align: center;
        margin-block: 50px;
        height: fit-content;
    }

    .a-left {
        width: 100%;
    }

    .a-card {
        width: 200px;
        height: 230px;
    }

    .a-img {
        object-fit: fill;
    }

    .a-card.bg {
        display: none;
    }

    .a-card-bg {
        display: none;
    }

    .a-right {
        padding: 20px;
    }

    .a-award {
        flex-direction: column;
        gap: 15px;
    }

}

@media screen and (max-width: 768px) {

    .a-right {
        padding: 0 0 0 50px;
    }

    .a-card {
        width: 90%;
    }

    .a-award {
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .a-award-desc {
        display: none;
    }
}


@media screen and (max-width: 919px) {
    .a-card {
        width: 90%;
    }

    .a-award {
        gap: 20px;
    }

    .a-right {
        margin-right: 20px;
    }
}