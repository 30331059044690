.p {
    width: 30%;
    height: 40vh;
    margin: 20px 10px;
    border: 2px solid rgb(243, 242, 242);
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    z-index: 2;
    transition: all 1s ease-in-out;
}

.p-browser {
    height: 20px;
    background-color: rgb(243, 242, 242);
    display: flex;
    align-items: center;
    position: sticky;
    z-index: 4;
}

.p-circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: 3px;
    background-color: #0da308;
    cursor: pointer;
}

.p-img {
    width: 100%;
    height: fit-content;
    transition: all 10s ease;
}

.p:hover .p-img {
    transform: translateY(-100%);
}

.p:hover {
    box-shadow: 0 0 10px rgb(187, 254, 86);
    transform: scale(1.1);
}

/* RESPONSIVE STYLES */

@media screen and (max-width: 480px) {

    .p {
        width: 100%;
        height: 40vh;
    }

}

@media screen and (max-width: 768px) {
    .p {
        width: 90%;
        margin: 0 auto;
        padding: 0;
        margin-bottom: 40px;
    }
}

