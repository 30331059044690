.t {
    width: 50px;
    height: 25px;
    border-radius: 20px;
    border: 1px solid #999;
    background-color: white;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-around;
    overflow: hidden;
    cursor: pointer;

}

.t-icon {
    width: 15px;
    height: 15px;
}

.t-btn {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #999;
    position: absolute;
    transition: all 1s ease-in-out;
    align-self: center;
}


/* RESPONSIVE STYLES */

@media screen and (max-width: 480px) {
    .t {
        right: 20px;

    }
}